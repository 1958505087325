import * as AuthGateway from './auth';
import * as ColorGateway from './color';
import * as SessionGateway from './session';
import * as EntityGateway from './entity';
import * as SharedEntityGateway from './shared-entity';
import * as SharedFolderGateway from './shared-folder';
import * as SharedLinkGateway from './shared-link';
import * as FolderGateway from './folder';
import * as FileGateway from './file';
import * as HistoryGateway from './history';
import * as PermissionGateway from './permission';
import * as PrivateGateway from './private';
import * as ContactGateway from './contact';
import * as FolderContactGateway from './folder-contact';
import * as IndexFieldGateway from './index-field';
import * as AppliedIndexGateway from './applied-index';
import * as IntegrationGateway from './integration';
import * as BulkGateway from './bulk';
import * as DownloadGateway from './download';
import * as InvitationGateway from './invitation';
import * as SharedUserGateway from './shared-user';

export {
  AuthGateway,
  ColorGateway,
  SessionGateway,
  FolderGateway,
  EntityGateway,
  SharedEntityGateway,
  SharedFolderGateway,
  SharedLinkGateway,
  FileGateway,
  HistoryGateway,
  PermissionGateway,
  PrivateGateway,
  ContactGateway,
  FolderContactGateway,
  IndexFieldGateway,
  AppliedIndexGateway,
  IntegrationGateway,
  BulkGateway,
  DownloadGateway,
  InvitationGateway,
  SharedUserGateway
};

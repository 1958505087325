import { get, destroy } from '../http';
import { sprintf } from '@/core/utils';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL as string;

export const Routes = {
  fetch: '/shared/entities',
  delete: '/shared/entities/%s'
};

export const fetch = async ({ folderId }: { folderId?: string }) => {
  let url = BASE_URL + Routes.fetch;
  if (folderId) {
    url += '?folder_id=' + folderId;
  }

  return get({ url });
};

export const removeShare = async ({ id }: { id: string }) => {
  const url = sprintf(BASE_URL + Routes.delete, id);

  return destroy({ url });
};

import { ReactNode } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';
import { Permissionable, PermissionableType } from '@imaging/types';

export default class ExternalShareDeleteHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser, permissionables: Permissionable[]) {
    super(history, user, permissionables);
    this.action = 'removed';
  }

  entityType(): string {
    if (this.history.attributes?.prev.folder_id) {
      return 'folder';
    } else {
      return 'file';
    }
  }

  entityName(): string {
    if (this.history.attributes?.prev.folder_id) {
      return this.history.attributes?.prev.folder.name;
    } else {
      return this.history.attributes?.prev.file.name;
    }
  }

  shareName(): string {
    return (
      this.permissionables.find(
        (user) =>
          user.permissionable_type === PermissionableType.USER &&
          user.permissionable_id === this.history.attributes?.prev.user_id
      )?.name || ''
    );
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        Access to the {this.entityType()}{' '}
        <span className="font-semibold break-all">{this.entityName()}</span> was {this.action} for{' '}
        <span className="font-semibold break-all">{this.shareName()}</span>.
      </div>
    );
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.userName}</b> {this.action} access to this {this.entityType()}
      </>
    );
  }

  detailIcon(): ReactNode {
    return <PaperAirplaneIcon className="-rotate-90 fill-support-error h-5" />;
  }
}

import { ReactNode } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class ShareLinkCreateHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser) {
    super(history, user);
    this.action = 'invited';
  }

  entityType(): string {
    if (this.history.attributes?.next.folder) {
      return 'folder';
    } else {
      return 'file';
    }
  }

  entityName(): string {
    if (this.history.attributes?.next.folder) {
      return this.history.attributes?.next.folder.name;
    } else {
      return this.history.attributes?.next.file.name;
    }
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        <span className="font-semibold break-all">{this.history.attributes?.next.email}</span> was{' '}
        {this.action} to share the {this.entityType()}{' '}
        <span className="font-semibold break-all">{this.entityName()}.</span>
      </div>
    );
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.userName}</b> sent an invite to this {this.entityType()}
      </>
    );
  }

  detailIcon(): ReactNode {
    return <PaperAirplaneIcon className="rotate-90 h-5 fill-support-success" />;
  }
}

export const buildEntityName = (name: string, existingEntityNames: string[]) => {
  const entityNames = existingEntityNames.map((name) => name.toLowerCase());

  if (!entityNames.includes(name.toLowerCase())) {
    return name;
  }

  let newName = '';
  for (let i = 1; ; i++) {
    newName = name + ` (${i})`;
    if (!entityNames.includes(newName.toLowerCase())) {
      return newName;
    }
  }
};

export const sentenceCase = (sentence: string) => {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
};

export const isEmail = (email: string) => {
  const regex =
    /([\w!#$%&'*+\-/=?^`{|}~]+\.)*[\w!#$%&'*+\-/=?^`{|}~]+@((((([a-z0-9][a-z0-9-]{0,62}[a-z0-9])|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?)/i;

  return regex.test(email);
};

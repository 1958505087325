import { get } from '../http';
import { sprintf } from '@/core/utils';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL as string;

export const Routes = {
  fetch: '/shared/invitation/%s'
};

export const fetch = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.fetch, id);
  return get({ url });
};

import { get, post } from '../http';
import { SearchFilters } from '../types';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL as string;

export const Routes = {
  fetch: '/entities',
  fetchSharedEntities: '/shared_entities',
  filtered: '/entities/filtered',
  archived: '/entities/archived',
  autocomplete: '/entities/search/autocomplete',
  search: '/entities/search',
  missingIndexes: '/entities/search/missing-indexes?page=',
  favorites: '/entities/favorites',
  shared: '/entities/shared'
};

export const fetch = async ({ folderId }: { folderId?: string | string[] }) => {
  let url = BASE_URL + Routes.fetch;
  if (folderId) {
    url += '?folder_id=' + folderId;
  }

  return get({ url });
};

export const fetchSharedEntities = async ({ folderId }: { folderId?: string }) => {
  let url = BASE_URL + Routes.fetchSharedEntities;
  if (folderId) {
    url += '?folder_id=' + folderId;
  }

  return get({ url });
};

export const fetchFiltered = async ({ filters }: { filters: SearchFilters }) => {
  const restUrl = BASE_URL + Routes.filtered;
  return post({ url: restUrl, body: JSON.stringify(filters) });
};

export const fetchArchived = async () => {
  const url = BASE_URL + Routes.archived;
  return get({ url });
};

export const autocomplete = async (query: string) => {
  const url = `${BASE_URL}${Routes.autocomplete}?query=${query}`;
  return get({ url });
};

export const search = async ({ filters }: { filters: SearchFilters }) => {
  const url = BASE_URL + Routes.search;
  return post({ url, body: JSON.stringify(filters) });
};

export const favorites = async () => {
  const url = BASE_URL + Routes.favorites;
  return get({ url });
};

export const shared = async () => {
  const url = BASE_URL + Routes.shared;
  return get({ url });
};

export const missingIndexes = async ({
  filters,
  pageParam
}: {
  filters: SearchFilters;
  pageParam: number | undefined;
}) => {
  pageParam = pageParam || 1;
  const url = BASE_URL + Routes.missingIndexes + pageParam;
  return post({ url, body: JSON.stringify(filters) });
};

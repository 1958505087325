import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  startOfToday,
  endOfToday,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  startOfYesterday
} from 'date-fns';

interface Filters {
  actions: string[];
  users: number[];
  date?: { start: string; end: string };
}

type DateFilterState = Record<string, { start: Date; end: Date }>;

export const dateFilterMap: DateFilterState = {
  Today: { start: startOfToday(), end: endOfToday() },
  Yesterday: { start: startOfYesterday(), end: endOfYesterday() },
  'This week': {
    start: startOfWeek(new Date()),
    end: endOfWeek(new Date())
  },
  'This month': {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date())
  },
  'This quarter': {
    start: startOfQuarter(new Date()),
    end: endOfQuarter(new Date())
  },
  'This year': {
    start: startOfYear(new Date()),
    end: endOfYear(new Date())
  }
};

const historyFilterMap: Record<string, any> = {
  Added: { entity_type: ['file', 'folder'], action: ['create'] },
  Archived: { entity_type: ['file', 'folder'], action: ['archive'] },
  Restored: { entity_type: ['file', 'folder'], action: ['restore'] },
  Renamed: { entity_type: ['file', 'folder'], action: ['rename'] },
  Deleted: { entity_type: ['file', 'folder'], action: ['delete'] },
  Moved: { entity_type: ['file', 'folder'], action: ['move'] },
  Copied: { entity_type: ['folder'], action: ['copy'] },
  'Permission added': { entity_type: ['folder_permission'], action: ['create_many'] },
  'Permission removed': { entity_type: ['folder_permission'], action: ['delete_many'] },
  'Invite sent': { entity_type: ['share_link'], action: ['create'] },
  'Invite accepted': { entity_type: ['external_share'], action: ['create'] },
  'Invite cancelled': { entity_type: ['share_link'], action: ['delete'] },
  'Share removed': { entity_type: ['external_share'], action: ['delete'] },
  Downloaded: { entity_type: ['file', 'folder'], action: ['download'] },
  'Index Added': { entity_type: ['applied_index'], action: ['create_many'] },
  'Index Removed': { entity_type: ['applied_index'], action: ['archive'] },
  'Index Updated': { entity_type: ['applied_index'], action: ['update'] },
  Linked: { entity_type: ['folder_contact'], action: ['create'] },
  Unlinked: { entity_type: ['folder_contact'], action: ['delete'] },
  'Log in': { entity_type: ['user'], action: ['login'] },
  'Log out': { entity_type: ['user'], action: ['logout'] }
};

export function buildHistoryFilters(filters: Filters) {
  const { actions, users, date } = filters;

  const apiFilters = actions.reduce((acc, action) => {
    const { entity_type: entityTypes, action: actionTypes } = historyFilterMap[action];
    if (acc['entity_type']) {
      acc['entity_type'].add(...entityTypes);
      acc['action'].add(...actionTypes);
    } else {
      acc['entity_type'] = new Set(entityTypes);
      acc['action'] = new Set(actionTypes);
    }
    return acc;
  }, {});

  return {
    entity_types: Array.from<string>(apiFilters['entity_type'] || []),
    actions: Array.from<string>(apiFilters['action'] || []),
    users,
    date
  };
}

import { ReactNode } from 'react';
import { PaperAirplaneIcon } from '@heroicons/react/solid';

import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';
import { Permissionable, PermissionableType } from '@imaging/types';

export default class ExternalShareCreateHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser, permissionables: Permissionable[]) {
    super(history, user, permissionables);
    this.action = 'invite';
  }

  entityType(): string {
    if (this.history.attributes?.next.folder_id) {
      return 'folder';
    } else {
      return 'file';
    }
  }

  entityName(): string {
    if (this.history.attributes?.next.folder_id) {
      return this.history.attributes?.next.folder_name;
    } else {
      return this.history.attributes?.next.file_name;
    }
  }

  shareName(): string {
    const user = this.permissionables.find(
      (user) =>
        user.permissionable_type === PermissionableType.USER &&
        user.permissionable_id === this.history.user_id
    );

    return user?.name || '';
  }

  detailText(): ReactNode {
    return (
      <div data-testid="history-detail-text">
        An {this.action} for the {this.entityType()}{' '}
        <span className="font-semibold break-all">{this.entityName()}</span> was accepted by{' '}
        <span className="font-semibold break-all">{this.shareName()}</span>.
      </div>
    );
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.shareName()}</b> accepted an invite
      </>
    );
  }

  detailIcon(): ReactNode {
    return <PaperAirplaneIcon className="rotate-90 h-5 fill-support-success" />;
  }
}

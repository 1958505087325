import { ReactNode } from 'react';
import { LoginIcon } from '@heroicons/react/solid';

import { Permissionable } from '@imaging/types';
import { EntityHistory } from '../entity-history';
import { DatabaseUser, HistoryItem } from '@/core/types';

export default class LoginHistory extends EntityHistory {
  constructor(history: HistoryItem, user: DatabaseUser, permissionables: Permissionable[]) {
    super(history, user, permissionables);
    this.action = 'logged in';
  }

  displayText(): ReactNode {
    return (
      <>
        <b>{this.userName}</b> {this.action}
      </>
    );
  }

  detailText(): string {
    if (this.history.attributes?.migrated) {
      return this.history.attributes?.migrated.description;
    } else {
      return `${this.userFullName()} ${this.action}`;
    }
  }

  detailIcon(): ReactNode {
    return <LoginIcon className="h-5 fill-support-success" />;
  }
}

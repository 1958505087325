// This forces a polyfill so that pdf.js works on older browser versions (safari). When the issue
// is resolved, we can remove. https://github.com/wojtekmaj/react-pdf/issues/1465
import 'core-js/features/array/at';
import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Inter } from 'next/font/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AwsS3 from '@uppy/aws-s3';
import { Uppy } from '@uppy/core';
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum';
import { init as fullStoryInit, isInitialized, shutdown } from '@fullstory/browser';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';

import SessionTimeout from '@/components/session-timeout';
import BeforeUnload from '@/components/before-unload';
import { NotificationProvider, SearchProvider, UiProvider, UppyProvider } from '@/core/context';
import { Environment, featureFlag, maxFileSize } from '@/core/utils';
import { s3PluginOptions } from '@/core/uppy/s3-plugin-options';
import '@/core/styles/globals.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

const uppyClient = new Uppy({
  restrictions: {
    maxFileSize // 100MB
  }
}).use(AwsS3, s3PluginOptions);

const inter = Inter({ subsets: ['latin'] });

if (Environment.isDeployed) {
  datadogRum.init({
    applicationId: '4895c98e-8371-4db2-b55a-98f5e62c6553',
    clientToken: 'pub7b8f8ee7a1e5eae1344f18f77acebac7',
    site: 'datadoghq.com',
    service: 'redtail-imaging',
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
    version: process.env.NEXT_PUBLIC_DATADOG_RELEASE,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK
  });

  datadogRum.startSessionReplayRecording();
}

const splitIoConfig = {
  core: {
    authorizationKey: process.env.NEXT_PUBLIC_SPLIT_IO_CLIENT_KEY || 'localhost',
    key: 'cc912c20-c7ef-11ec-9f86-82eca7ca4362'
  },
  // These options only apply to localhost mode
  features: {
    [featureFlag.switchIn]: 'off',
    [featureFlag.fileStats]: 'on',
    [featureFlag.folderColors]: 'on',
    [featureFlag.sharing]: 'on'
  }
};

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (!isInitialized()) {
      fullStoryInit({ orgId: 'o-1EQZY8-na1' });
    }

    return () => {
      shutdown();
    };
  }, []);

  return (
    <>
      <Head>
        <title>Redtail Imaging</title>
        <style jsx global>
          {`
            html {
              font-family: ${inter.style.fontFamily};
            }
          `}
        </style>
      </Head>
      <SplitFactoryProvider config={splitIoConfig}>
        <QueryClientProvider client={queryClient}>
          <UppyProvider client={uppyClient}>
            <SearchProvider>
              <UiProvider>
                <NotificationProvider>
                  <Component {...pageProps} />
                  {pageProps.user && <SessionTimeout currentUser={pageProps.user} />}
                  <BeforeUnload />
                </NotificationProvider>
              </UiProvider>
            </SearchProvider>
          </UppyProvider>
        </QueryClientProvider>
      </SplitFactoryProvider>
    </>
  );
}
export default MyApp;

import { post } from '../http';
import { CreateShareUserProps } from '@/core/types';

const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

const Routes = {
  signIn: '/api/rest/shared/signin',
  signUp: '/api/rest/shared/signup'
};

export const signIn = async (login: { username: string; password: string }, inviteId: string) => {
  const url = BASE_URL + Routes.signIn;
  return post({
    url,
    body: JSON.stringify({ ...login, inviteId })
  });
};

export const signUp = async (user: CreateShareUserProps, shareId: string) => {
  const url = BASE_URL + Routes.signUp;
  return post({
    url,
    body: JSON.stringify({ ...user, shareId })
  });
};

import { get } from '../http';
import { sprintf } from '@/core/utils';

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL as string;

export const Routes = {
  breadcrumbs: '/shared/folders/%s/breadcrumbs'
};

export const fetchBreadcrumbs = async ({ folderId }: { folderId?: string }) => {
  const url = BASE_URL + sprintf(Routes.breadcrumbs, folderId);
  return get({ url });
};

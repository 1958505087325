import { destroy as destroyRequest, post } from '../http';
import { sprintf } from '@/core/utils';

interface CreateProps {
  folder_id: string;
  invites: string[];
}

const BASE_URL = process.env.NEXT_PUBLIC_REST_API_URL || process.env.REST_API_URL;

export const Routes = {
  create: '/shared/links',
  destroy: '/shared/links/%s',
  invite: '/shared/links/%s/invite',
  accept: '/shared/links/%s/accept'
};

export const create = async (props: CreateProps) => {
  const url = BASE_URL + Routes.create;
  return post({ url, body: JSON.stringify(props) });
};

export const destroy = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.destroy, id);
  return destroyRequest({ url });
};

export const invite = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.invite, id);
  return post({ url });
};

export const accept = async ({ id }: { id: string }) => {
  const url = BASE_URL + sprintf(Routes.accept, id);
  return post({ url });
};

import { ComponentProps } from 'react';

export function RedtailImagingLogo({ ...props }: ComponentProps<'svg'>) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 52"
      {...props}
    >
      <path
        className="fill-[#c81414]"
        d="M62,43V9h9.57c9.56,0,13,3.58,13,10.25,0,5.52-4.72,8.65-9.92,9.88,1.76,6.9,6.63,9.16,10.93,9.16V43c-8,0-15.7-4-16.66-18.17h2.7c4.48,0,7.23-1.8,7.23-5.58s-2.38-5.57-7.24-5.57h-4V43Z"
      />
      <path
        className="fill-[#c81414]"
        d="M109.81,43H90.57V9h19.24v4.7H96.13V23h12.81v4.66H96.13V38.29h13.68Z"
      />
      <path
        className="fill-[#c81414]"
        d="M143.47,25.69q0,8.42-4.68,12.87T125.33,43h-9.51V9h10.51q8.12,0,12.63,4.37T143.47,25.69Zm-5.86.18q0-12.21-11.42-12.21h-4.81V38.34h4Q137.62,38.34,137.61,25.87Z"
      />
      <path className="fill-[#c81414]" d="M159.66,43H154.1V13.78h-10V9h25.56v4.77h-10Z" />
      <path
        className="fill-[#c81414]"
        d="M191.66,43l-3.39-9.4H175.33L172,43h-5.83L178.85,9h6l12.67,34Zm-4.85-14.17-3.17-9.22c-.23-.62-.55-1.59-1-2.92s-.69-2.3-.84-2.92A52.76,52.76,0,0,1,180,20L177,28.84Z"
      />
      <path className="fill-[#c81414]" d="M201.36,43V9h5.56V43Z" />
      <path className="fill-[#c81414]" d="M214.6,43V9h5.56V38.24h14.39V43Z" />
      <path
        className="fill-[#c81414]"
        d="M26,2A24,24,0,1,0,50,26,24,24,0,0,0,26,2Zm0,46A22,22,0,1,1,48,26,22,22,0,0,1,26,48Z"
      />
      <path
        className="fill-[#c81414]"
        d="M29.18,12.57a9.53,9.53,0,0,0-3.49,2.1L14.45,25.9a1,1,0,0,0,1.42,1.42L27.1,16.08c.44-.43,4.4-4.11,8.51,0s.43,8.08,0,8.51L22.38,37.82a3.51,3.51,0,0,1-5.2,0,3.54,3.54,0,0,1,0-5.2L28.75,21.05a1.34,1.34,0,1,1,1.9,1.89l-8.76,8.75a1,1,0,0,0,1.42,1.42l8.75-8.76a3.34,3.34,0,0,0-4.72-4.72L15.76,31.21a5.48,5.48,0,0,0,0,8A5.46,5.46,0,0,0,19.66,41a6.13,6.13,0,0,0,4.13-1.77L37,26c2-2,4.49-6.86,0-11.34A7.26,7.26,0,0,0,29.18,12.57Z"
      />
      <rect className="fill-[#c81414]" x="247.01" y="19.72" width="3.51" height="23.28" />
      <polygon
        className="fill-[#c81414]"
        points="267.58 37.82 267.31 37.82 259.89 19.72 255.63 19.72 255.63 43 258.97 43 258.97 26.14 259.19 26.14 266.06 42.97 268.83 42.97 275.69 26.15 275.91 26.15 275.91 43 279.25 43 279.25 19.72 274.99 19.72 267.58 37.82"
      />
      <path
        className="fill-[#c81414]"
        d="M291,19.72,282.6,43h3.73l2.13-6.16h9.1L299.68,43h3.73L295,19.72Zm-1.48,14.16L292.92,24h.18l3.43,9.93Z"
      />
      <path
        className="fill-[#c81414]"
        d="M316,34h5.76a5.89,5.89,0,0,1-6.29,6.12c-4.11,0-7.09-3.1-7.09-8.76s3-8.74,6.95-8.74a6,6,0,0,1,6.06,4.46H325c-.78-4.53-4.66-7.67-9.68-7.67-6,0-10.42,4.54-10.42,12s4.27,12,10.58,12c5.65,0,9.68-3.71,9.68-9.58V31.09H316Z"
      />
      <rect className="fill-[#c81414]" x="329.63" y="19.72" width="3.51" height="23.28" />
      <polygon
        className="fill-[#c81414]"
        points="353.57 36.82 353.35 36.82 341.51 19.72 338.26 19.72 338.26 43 341.77 43 341.77 25.93 341.98 25.93 353.82 43 357.05 43 357.05 19.72 353.57 19.72 353.57 36.82"
      />
      <path
        className="fill-[#c81414]"
        d="M372.52,31.09V34h5.75A5.88,5.88,0,0,1,372,40.11c-4.11,0-7.1-3.1-7.1-8.76s3-8.74,7-8.74a6,6,0,0,1,6.06,4.46h3.6c-.78-4.53-4.66-7.67-9.68-7.67-6,0-10.43,4.54-10.43,12s4.28,12,10.59,12c5.65,0,9.68-3.71,9.68-9.58V31.09Z"
      />
    </svg>
  );
}
